import { Section, SectionHeading } from "components"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const ClutchReview = () => {
	return (
		<>
			<Section>
				<Container>
					<Row className="justify-content-center">
						<Col md={9}>
							<SectionHeading>Clutch Reviews</SectionHeading>
							<div
								class="clutch-widget"
								data-nofollow="true"
								data-url="https://widget.clutch.co"
								data-widget-type="8"
								data-height="auto"
								data-expandifr="true"
								data-snippets="true"
								data-clutchcompany-id="1704131"
							></div>
						</Col>
					</Row>
				</Container>
			</Section>
		</>
	)
}

export default ClutchReview
