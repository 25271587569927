import React from "react"
import { Col, Row } from "react-bootstrap"
import * as styles from "./styles.module.scss"
import { Container, SectionHeading, Section, Button } from "components"
import "../../../../../node_modules/slick-carousel/slick/slick.css"
import "../../../../../node_modules/slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"

const VenChatVideo = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					dots: false,
					slidesToShow: 1,
					arrows: true,
				},
			},
		],
	}

	return (
		<Section className={styles.banner}>
			<Container>
				<Row className="justify-content-center">
					<Col md={9}>
						<SectionHeading className="text-white text-center mb-3">Venchat</SectionHeading>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md={7}>
						<p className="text-white text-center pb-4">
							Venchat is a coffee chat series dedicated to everything about design, development, and the overall startup
							ecosystem.
						</p>
						<Slider {...settings} className="mt-5">
							<div>
								<iframe
									className={styles.iframWirdth}
									height="355"
									src="https://www.youtube.com/embed/Wdt-yYsnf2Y?rel=0"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								></iframe>
							</div>
							<div>
								<iframe
									className={styles.iframWirdth}
									height="355"
									src="https://www.youtube.com/embed/PZv9cToWMT0"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								></iframe>
							</div>
							<div>
								<iframe
									className={styles.iframWirdth}
									height="355"
									src="https://www.youtube.com/embed/Cxr00Z5V_Og"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								></iframe>
							</div>
							<div>
								<iframe
									className={styles.iframWirdth}
									height="355"
									src="https://www.youtube.com/embed/bd8ozK937Co"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
									allowfullscreen
								></iframe>
							</div>
						</Slider>
						<div className="text-center mt-5">
							<Button to="https://www.youtube.com/@venchat425" primary>
								View Our Channel
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default VenChatVideo
