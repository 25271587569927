import React from "react"
import { Container, Section } from "components"
import { Col, Row } from "react-bootstrap"
import { InlineWidget } from "react-calendly"
import SectionHeading from "../SectionHeading"
import * as styles from "./styles.module.scss"

const ScheduleMeet = () => {
	return (
		<Section className={styles.banner}>
			<Container>
				<Row>
					<Col>
						<SectionHeading className="text-white mb-0">SCHEDULE A MEETING</SectionHeading>
						<div className="App">
							<InlineWidget url="https://calendly.com/shahzeb-khalid/30min" />
						</div>
					</Col>
				</Row>
			</Container>
		</Section>
	)
}

export default ScheduleMeet
