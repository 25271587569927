import React from "react"
import { content } from "content/home"
import {
	BlogPostsGrid,
	Locations,
	Banner,
	Hero,
	Testimonials,
	Kickstart,
	OurClients,
	OurProcess,
	OurServices,
	SectionHeading,
	Container,
	Main,
	Section,
} from "components"
import { graphql, useStaticQuery } from "gatsby"
import ScheduleMeet from "components/base/ScheduleMeet"
import ClutchReview from "components/pages/home/ClutchReview"
import { Col, Image, Row } from "react-bootstrap"
import VenChatVideo from "components/pages/home/VenChatVideo"

const Home = () => {
	const homeHero = useStaticQuery(graphql`
		query homeHero {
			file(relativePath: { eq: "hero_home.png" }) {
				relativePath
				childImageSharp {
					gatsbyImageData
				}
			}
		}
	`)

	return (
		<>
			<Main>
				<Hero
					img={homeHero}
					title={content.hero.title}
					buttonText={content.hero.button.text}
					buttonLink={content.hero.button.path}
				/>

				<div className="pt-5 pb-4 bg-light">
					<Container>
						<Row className="align-items-center" xs={2} sm={3} md={6}>
							{content.awards.list.map((item, index) => (
								<Col key={index} style={{ textAlign: "center", marginBottom: 20 }}>
									<Image src={item.badge} width={item.width} />
								</Col>
							))}
						</Row>
					</Container>
				</div>

				<Banner type="dark" icon={content.banner.icon} title={content.banner.title} text={content.banner.text} />
				<OurServices />
				<OurProcess />
				<VenChatVideo />
				<OurClients title={content.clients.title} />
				<ScheduleMeet />

				<Testimonials />
				<ClutchReview />

				<Container>
					<Section>
						<SectionHeading>Latest Articles</SectionHeading>
						<BlogPostsGrid count={3} />
					</Section>
				</Container>

				<Locations />
				<Kickstart />
			</Main>
		</>
	)
}

export default Home
